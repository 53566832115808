@use "../../../styles/variables";
@use "../../../styles/specifics";
@use "../../../styles/mediaQueries";
@use "../../../styles/textStyles";

.tbProjectMini {
  @include textStyles.body;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: variables.$base-factor * 3;
  padding: variables.$base-factor * 5;
  background-color: variables.$white101;
  border: 1px solid variables.$white107;
  border-radius: variables.$base-factor * 2;
  text-align: left;
  text-decoration: none;
  color: variables.$black100;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: background 0.1s ease-out;

  &:hover {
    background-color: variables.$white102;
  }

  @mixin miniBlockStyle {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .tbProjectMiniHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: variables.$base-factor * 3;
      padding-bottom: variables.$base-factor * 3;
      border-bottom: 1px solid variables.$white107;

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > *:not(span) {
        overflow: visible;
      }
    }
  }

  &Homepage {
    @include mediaQueries.lg(max-width) {
      .tbProjectMiniLastAuditDate {
        display: none;
      }
    }
  }

  &Auto {
    & > *:not(:last-child) {
      overflow: visible;
    }

    @include mediaQueries.sm(max-width) {
      @include miniBlockStyle;
    }
  }

  &Header {
    gap: variables.$base-factor * 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &NameContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: variables.$base-factor * 3;
    }
  }

  &Line {
    & > *:not(:last-child) {
      overflow: visible;
    }
  }

  &Block {
    @include miniBlockStyle;
  }

  &Favicon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: variables.$base-factor * 5;
    height: variables.$base-factor * 5;
    border-radius: variables.$base-factor;
    object-fit: cover;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: variables.$base-factor;
    }

    &NotFound {
      width: variables.$base-factor * 5;
      height: variables.$base-factor * 5;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: variables.$white107;
      border-radius: variables.$base-factor;

      & > svg {
        width: variables.$base-factor * 4;

        @include specifics.colorIcons(variables.$black40);
      }
    }
  }
}
