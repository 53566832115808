@use "../../../styles/variables";
@use "../../../styles/textStyles";
@use "../../../styles/specifics";
@use "../../../styles/utils";
@use "../../../styles/mediaQueries";

$tag-height: variables.$base-factor * 6;

.tbTag {
  @include textStyles.tag;

  align-items: center;
  border-radius: variables.$border-radius2;
  display: inline-flex;
  height: $tag-height;
  padding: 0 variables.$base-factor * 2;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  width: fit-content;
  box-sizing: border-box;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: variables.$base-factor * 3;
  }

  & > svg {
    @include specifics.autoStrokeWidthIcons;
    @include specifics.autoColorChainIcon;

    min-width: variables.$base-factor * 4;
    max-width: variables.$base-factor * 4;
  }

  &:not(.tbTagOnlyIcon) {
    & > svg {
      margin-right: variables.$base-factor;
    }
  }

  &-removeButton {
    @include utils.allUnset;

    height: $tag-height;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0;
      margin-left: variables.$base-factor * 2;
      stroke-width: 3px;
    }
  }

  &Black {
    background-color: variables.$black100;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$black100);
    }
  }

  &Outline {
    border: 1px solid variables.$black100;
  }

  &Grey {
    background-color: variables.$white101;
    color: variables.$black80;

    svg {
      @include specifics.colorIcons(variables.$black80, variables.$white101);
    }
  }

  &Grey2 {
    background-color: variables.$white102;
    color: variables.$black80;

    svg {
      @include specifics.colorIcons(variables.$black80, variables.$white102);
    }
  }

  &Valid {
    background-color: variables.$valid;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$white101);
    }
  }

  &Error {
    background-color: variables.$error;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$white101);
    }
  }

  &Warning {
    background-color: variables.$warning;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$white101);
    }
  }

  &Warning2 {
    background-color: variables.$warning2;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$white101);
    }
  }

  &Primary {
    background-color: variables.$purple100;
    color: variables.$white90;

    svg {
      @include specifics.colorIcons(variables.$white90, variables.$white101);
    }
  }
}
