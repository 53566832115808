@use "../../../styles/variables";
@use "../../../styles/textStyles";

$pagination-height: variables.$base-factor * 9;
$button-width: variables.$base-factor * 14;

.tbPagination {
  display: flex;
  align-items: center;
  background-color: variables.$white101;
  justify-content: space-between;
  height: $pagination-height;
  max-width: variables.$base-factor * 83;
  width: 100%;
  border-radius: variables.$border-radius2;
  overflow: hidden;
  color: variables.$black100;

  span {
    @include textStyles.tag;

    width: $button-width;
    height: $pagination-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tbPaginationButton {
  background-color: transparent;
  width: $button-width;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:not(:disabled):hover {
    background-color: variables.$white102;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  svg {
    width: variables.$base-factor * 4;
  }
}

.tbIndexButton {
  @include textStyles.label;

  font-family: inherit;
  width: $button-width;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background-color: variables.$white102;
  }
}

.tbIndexButtonActive {
  color: variables.$purple100;
}
