@use "_variables";
@use "_mediaQueries";

.homePage {
  background-color: variables.$white100;
  padding-bottom: variables.$base-factor * 16;

  &Container {
    flex-direction: column;
    max-width: fit-content;
    gap: variables.$base-factor * 4;
    min-height: calc(100vh - variables.$nav-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mediaQueries.sm(max-width) {
      align-items: flex-start;
    }

    & > * {
      &:first-child {
        border-bottom: unset;
      }
    }

    &Title {
      align-self: center;
      text-align: center;
      width: 100%;
      border-bottom: 1px solid variables.$white104 !important;
      padding-bottom: variables.$base-factor * 8;
      margin-bottom: variables.$base-factor * 8;
    }

    &Tabs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: variables.$base-factor * 3;
      color: variables.$white127;

      & > h5 {
        border-bottom: 1px solid rgba(variables.$purple100, 0);
        padding-bottom: variables.$base-factor * 3;
        cursor: pointer;
        transition: color 0.15s ease-out, border-bottom-color 0.15s ease-in;

        &:not(.homePageContainerTabsActive):hover {
          color: variables.$black60;
        }
      }

      &Active {
        color: variables.$black100;
        padding-bottom: variables.$base-factor * 3;
        border-bottom-color: variables.$purple100 !important;
      }
    }

    &Pagination {
      align-self: center;
      margin-top: 24px;
    }
  }

  &Section {
    width: 964px;
    max-width: 964px;
    min-height: 305px;
    flex-direction: column;
    gap: variables.$base-factor * 4;
    display: none;

    &Active {
      display: flex;
    }

    @include mediaQueries.lg(max-width) {
      width: 750px;
      max-width: 750px;
    }

    @include mediaQueries.md(max-width) {
      max-width: 100%;
    }
  }

  &SearchAuditor {
    box-sizing: border-box;
    align-items: flex-start;
    gap: variables.$base-factor !important;

    & > div {
      gap: variables.$base-factor;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr min-content;
      width: 100%;

      @include mediaQueries.lg(max-width) {
        max-width: 100%;
        grid-template-columns: 1fr 1fr;
      }

      @include mediaQueries.md(max-width) {
        max-width: 100%;
        grid-template-columns: 1fr;
      }

      & > * {
        min-width: 0;
      }
    }

    & > * {
      min-width: 0;
    }
  }

  &SearchProject {
    display: grid !important;
    grid-template-columns: 1fr min-content;
    gap: variables.$base-factor !important;

    & > * {
      min-width: 0;
    }
  }

  &Result {
    @include mediaQueries.sm(min-width) {
      display: grid;
      grid-template-columns: 1fr repeat(6, max-content) 1fr;
      gap: variables.$base-factor * 3;

      // Mini
      & > * {
        display: grid !important;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;

        // Header
        & > *:nth-child(2) {
          text-align: right;
          display: grid !important;
          grid-column: span 3;
          grid-template-columns: subgrid;
          justify-content: end;

          & > div:first-child {
            text-align: right;
            justify-content: flex-end;
          }
        }

        // Tvs tag
        & > *:nth-child(3) {
          justify-self: center;
        }
      }
    }

    @include mediaQueries.sm(max-width) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: variables.$base-factor * 3;

      & > * {
        width: 100%;
        max-width: 100%;
        align-items: flex-start;
      }
    }
  }

  &Pagination {
    align-self: center;
  }
}
