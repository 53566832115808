@use "../../../styles/specifics";
@use "../../../styles/variables";

.tbTagChains {
  &Multi {
    display: flex;
    align-items: center;
    gap: variables.$base-factor * 2;

    svg {
      @include specifics.autoStrokeWidthIcons;
      @include specifics.autoColorChainIcon;

      width: variables.$base-factor * 4;
      height: variables.$base-factor * 4;
    }
  }

  &Single {
    svg {
      @include specifics.autoStrokeWidthIcons;
      @include specifics.autoColorChainIcon;

      width: variables.$base-factor * 4;
      height: variables.$base-factor * 4;
    }
  }
}
